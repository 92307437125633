import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAPI } from "@/http";
import axios from "axios";
import auth from "@/utils/auth";
import { RE1_BACKEND_URL } from "@/ui-constants";
const initialState = {
  isLoading: false,
  is_authenticated: false,
  data: {
  },
};

export const fetchProfileDetails = createAsyncThunk(
  "profile/fetch_profile",
  async (data, thunkAPI) => {
    const response = await customAPI(
      "get",
      `/users/user-profile`,
      {},
      {
        dispatch: thunkAPI.dispatch,
        loaderMsg: "Loading your data please wait",
        disableLoader: true,
        disableSnackbar: false,
      }
    );
    return response;
  }
);

export const deleteProfilePicture = createAsyncThunk(
  "profile/delete_profile_picture",
  async (data, thunkAPI) => {
    const response = await customAPI(
      "patch",
      `${RE1_BACKEND_URL}/user-profile/remove`,
      data,
      {
        dispatch: thunkAPI.dispatch,
        loaderMsg: "Deleting your profile picture",
        disableLoader: false,
        disableSnackbar: false,
      }
    );
    return response;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (data, thunkAPI) => {
    const idToken = await auth();
    let config = {
      method: "patch",
      url: `${RE1_BACKEND_URL}/user-profile`,
      headers: {
        Authorization: idToken,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      processData: false,
      mimeType: "multipart/form-data",
      contentType: false,
      data: data,
    };
    const response = await axios.request(config)
    return response.data
  }
);

// export const updateProfileRE2 = createAsyncThunk(
//   "profile/update",
//   async (data, thunkAPI) => {
//     const idToken = await auth();
//     let config = {
//       method: "patch",
//       url: `${RE1_BACKEND_URL}/user-profile`,
//       headers: {
//         Authorization: idToken,
//         Accept: "application/json",
//         "Content-Type": "multipart/form-data",
//       },
//       processData: false,
//       mimeType: "multipart/form-data",
//       contentType: false,
//       data: data,
//     };
//     const response = await axios.request(config)
//     return response.data
//   }
// );

export const updateProfileRE2 = async (data,email) => {
  const response = await customAPI(
    "patch",
    `/users/${email}`,
    data,
    {
      loaderMsg: "",
      disableLoader: true,
      disableSnackbar: true,
    }
  );
  return response;
};


const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      state.is_authenticated= true,
      state.data = action.payload;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.data = action.payload;
      })
      .addCase(deleteProfilePicture.fulfilled, (state,action) => {
        state.data = action.payload;
      })
  },
});

export default profileSlice.reducer;
