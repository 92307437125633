import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: ["Post"],
  baseQuery: fetchBaseQuery({ baseUrl: "https://fakestoreapi.com" }),
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: () => "/products",
      providesTags: ["Post"],
    }),
    addNewPost: builder.mutation({
      query: (initialPost) => ({
        url: "/products",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: ["Post"],
    }),
    addNewPost1: builder.mutation({
      query: (initialPost) => ({
        url: "/products",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: ["Post"],
    }),
  }),
});

export const { useGetPostsQuery, useAddNewPostMutation } = apiSlice;
