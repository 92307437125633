import { customAPIV2 } from "./http";
import { showLoading, hideLoading } from "@/redux/slices/loader";
import { openSnackbar } from "@/redux/slices/snackbar";
import { store } from "@/redux/store";
import { RE1_BACKEND_URL } from "@/ui-constants";
import Cookies from "js-cookie";

export const createNewProject = async (data) => {
  try {
    store.dispatch(showLoading({ message: "creating project..." }));
    const project = await customAPIV2("post", `/project`, data.project, {
      loaderMsg: "Creating media",
      disableLoader: true,
    });
    const media = await customAPIV2(
      "post",
      `/media`,
      { project_id: project._id, ...data.media },
      {
        loaderMsg: "Creating media",
        disableLoader: true,
      }
    );
    return media;
  } catch (error) {
    throw new Error(error);
  } finally {
    store.dispatch(hideLoading({}));
  }
};

export const getPaymentUrl = async (data) => {
  const fprom_tid = Cookies.get("_fprom_tid");
  const response = await customAPIV2(
    "post",
    `/subscription/createCheckoutSession`,
    {...data, fprom_tid:fprom_tid||('fp_'+(new Date).getTime())},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const upgradeDowngradePlan = async (data) => {
  const response = await customAPIV2(
    "post",
    `/subscription/updateSubscription`,
    {...data},
    {
      loaderMsg: "Processing your request",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const checkIsSubscribed = async (data) => {
  const response = await customAPIV2(
    "get",
    `/subscription/checkPaymentDetails`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const checkProfileDetails = async () => {
  const response = await customAPIV2(
    "get",
    `/users/user-profile`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getPaymentPortal = async (data) => {
  const response = await customAPIV2(
    "get",
    `/subscription/getBillingPortal`,
    data,
    {
      loaderMsg: "Opening in new tab",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const cancelSubscription = async (data) => {
  const response = await customAPIV2(
    "post",
    `/subscription/cancelSubscription`,
    data,
    {
      loaderMsg: "Please wait",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getMyProjects = async (data) => {
  const response = await customAPIV2(
    "get",
    `/project?${data}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const contactFormAPI = async (data) => {
  const response = await customAPIV2("post", `/public/addQuery`, data, {
    loaderMsg: "Submitting",
    disableLoader: false,
    disableSnackbar: false,
  });
  return response;
};

export const renameProjectAPI = async (data) => {
  const response = await customAPIV2(
    "patch",
    `/project/${data.renameId}`,
    {
      name: data.name,
    },
    {
      loaderMsg: "Renaming project please wait",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const deleteProjectAPI = async (data) => {
  const response = await customAPIV2(
    "delete",
    `/project/${data}`,
    {
      deleted_reasons: ["privacy", "others"],
    },
    {
      loaderMsg: "Deleting your data please wait",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getMedia = async (data) => {
  const response = await customAPIV2("get", `/media?${data}`, data, {
    loaderMsg: "Loading",
    disableLoader: false,
    disableSnackbar: false,
  });
  return response;
};

export const getGenerations = async (data) => {
  const response = await customAPIV2(
    "get",
    `/generate?${data}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getDownloadsAPI = async (data) => {
  const response = await customAPIV2(
    "get",
    `/download?${data}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getAllDownloadsAPI = async (data) => {
  const response = await customAPIV2(
    "get",
    `download/getAllDownloads?${data}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};



export const downloadImage = async (data) => {
  const response = await customAPIV2("post", `/download`, data, {
    loaderMsg: "Downloading image",
    disableLoader: false,
    disableSnackbar: false,
  });
  return response;
};

export const getUpscaleImage = async (data) => {
  const response = await customAPIV2(
    "get",
    `/download/${data}`,
    {},
    {
      loaderMsg: "Downloading image",
      disableLoader: true,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getFavoritesAPI = async (data) => {
  const response = await customAPIV2(
    "get",
    `/generate/getFavourites?${data}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getAllFavoritesAPI = async (data) => {
  const response = await customAPIV2(
    "get",
    `/generate/getAllFavourites?${data}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const deleteGenerationsAPI = async (data) => {
  const response = await customAPIV2("delete", `/generate`, data, {
    loaderMsg: "Deleting your data please wait",
    disableLoader: false,
    disableSnackbar: false,
  });
  return response;
};

export const deleteOutputsAPI = async (data) => {
  
  const response = await customAPIV2("delete", `/generate/output/${data.output_id}`, data.body, {
    loaderMsg: "Deleting your data please wait",
    disableLoader: false,
    disableSnackbar: false,
  });
  return response;
};

export const markFavorites = async (data) => {
  const response = await customAPIV2(
    "patch",
    `/generate/output/${data.generationId}`,
    data.body,
    {
      loaderMsg: data.msg,
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const updateSpaceType = async (data) => {
  const response = await customAPIV2(
    "patch",
    `/media/${data.mediaId}`,
    data.body,
    {
      loaderMsg: data.msg,
      disableLoader: true,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getOldGenerations = async (data) => {
  const response = await customAPIV2(
    "POST",
    `${RE1_BACKEND_URL}/history`,
    data,
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getUnassignedGenerationsAPI = async (data) => {
  const unassignedProjects = await customAPIV2(
    "get",
    `/media/getUnassignedMedias?${data}`,
    {},
    {
      loaderMsg: "Loading",
    }
  );
  return unassignedProjects;
};

export const deleteOldGenerationsAPI = async (data) => {
  const response = await customAPIV2(
    "DELETE",
    `${RE1_BACKEND_URL}/history`,
    data,
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getOldDownloadsAPI = async (data) => {
  const response = await customAPIV2(
    "POST",
    `${RE1_BACKEND_URL}/download/get-all`,
    data,
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const downloadOldImage = async (data) => {
  const response = await customAPIV2(
    "POST",
    `${RE1_BACKEND_URL}/download`,
    data,
    {
      loaderMsg: "Downloading image",
      disableLoader: false,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getOldUpscaleImage = async (data) => {
  const response = await customAPIV2(
    "get",
    `${RE1_BACKEND_URL}/download?download_job_id=${data}`,
    {},
    {
      loaderMsg: "Downloading image",
      disableLoader: true,
      disableSnackbar: false,
    }
  );
  return response;
};

export const getOldFavorites = async (data) => {
  const response = await customAPIV2("POST", `${RE1_BACKEND_URL}/like`, data, {
    loaderMsg: "Loading",
    disableLoader: false,
    disableSnackbar: false,
  });
  return response;
};

export const add2ExistingProject = async (data) => {
  try {
    store.dispatch(showLoading({ message: "creating project..." }));
    const project = await customAPIV2("post", `/project`, data.project, {
      loaderMsg: "Creating media",
      disableLoader: true,
    });
    const media = await customAPIV2(
      "post",
      `/media`,
      { project_id: project._id, ...data.media },
      {
        loaderMsg: "Creating media",
        disableLoader: true,
      }
    );
    return media;
  } catch (error) {
    throw new Error(error);
  } finally {
    store.dispatch(hideLoading({}));
  }
};

export const getProjectList = async () => {
  const projects = await customAPIV2(
    "get",
    `/project`,
    {},
    {
      loaderMsg: "Fetching projects",
    }
  );
  return projects;
};

export const createMask = async (data) => {
  const masks = await customAPIV2(
    "patch",
    `/media/mask/${data.mediaId}`,
    data.mask,
    {
      loaderMsg: "Creating masks",
    }
  );
  return masks;
};

export const getMask = async (data) => {
  const masks = await customAPIV2(
    "get",
    `/media/mask/${data.mediaId}`,
    {},
    {
      disableLoader: true,
      loaderMsg: "Fetching masks",
    }
  );
  return masks;
};

export const createGeneration = async (data) => {
  const generations = await customAPIV2("post", `/generate`, data.generate, {
    disableLoader: false,
    loaderMsg: "Generating design",
  });
  return generations;
};

export const getGeneration = async (data) => {
  const generations = await customAPIV2(
    "get",
    `/generate/${data.generationId}`,
    {},
    {
      disableLoader: true,
      loaderMsg: "Generating design",
    }
  );
  return generations;
};

export const pollApiUntilSuccess = async (func, data, interval) => {
  store.dispatch(showLoading({ message: "Fetching masks..." }));
  const polling = async () => {
    try {
      const response = await func(data);
      if (response) {
        if (response.job_status === "done") {
          store.dispatch(hideLoading({}));
          return response; // Return the success response
        }
      }
    } catch (error) {
      throw new Error(error);
    }

    // Retry after the specified interval
    await new Promise((resolve) => setTimeout(resolve, interval));
    return polling(); // Recursive call
  };

  // Start polling
  return polling();
};

export const RedesignFurther = async (img, project_id, solution_name) => {
  try {
  } catch (error) {
  } finally {
  }
};

export const getDiscountAsPerCountry = async () => {
  const response = await customAPIV2(
    "get",
    // 'http://localhost:3000/pricing/?pd_identifier=15e71daf-eff8-48db-9b79-0eccf1c2a659',
    `https://api.paritydeals.com/api/v1/deals/discount/?url=${window.location.href}`,
    {},
    {
      loaderMsg: "Loading",
      disableLoader: false,
      disableSnackbar: true,
    }
  );
  return response;
};
