import { customAPI } from "@/http";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  existingProjectList: [],
};

export const getExistingProjectListThunk = createAsyncThunk(
  "existing-project-list/getExistingProjectListThunk",
  async (data, thunkAPI) => {
    const projects = await customAPI(
      "get",
      `/project?limit=1000&sortBy=createdAt:desc`,
      {},
      {
        dispatch: thunkAPI.dispatch,
        disableLoader: true,
        loaderMsg: "Fetching projects",
      }
    );

    let formattedList = projects?.results.filter(
      (project) => project.type !== "surprise"
    );
    return { list: formattedList,};
  }
);
const existingProjectListSlice = createSlice({
  name: "existing-project-list",
  initialState,
  reducers: {
    setExistingProjectList: (state, action) => {
      state.existingProjectList = action.payload.list;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExistingProjectListThunk.fulfilled, (state, action) => {
      state.existingProjectList = action.payload.list;
      state.isLoading = false;
    });
  },
});

export const { setExistingProjectList } = existingProjectListSlice.actions;

export default existingProjectListSlice.reducer;
