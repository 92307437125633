import { STRIPE_BASIC_PLAN_ID, STRIPE_FREEMIUM_PLAN_ID, STRIPE_MONTHLY_49_PRO_PLAN_ID } from "@/ui-constants";

const isSubscriber = (subscriptionData) => {
  if (subscriptionData.credits_left > 0) {
    return true;
  } else if (
    subscriptionData.plan_id == STRIPE_FREEMIUM_PLAN_ID &&
    subscriptionData.credits_left > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export default isSubscriber;
