import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import counterReducer from "./slices/counterSlice";
import snackbar from './slices/snackbar';
import pricing from './slices/pricing';
import commonPopup from "./slices/commonPopup";
import loader from "./slices/loader";
import topbar from './slices/topbar';
import solutions from './slices/solutions';
import surpriseMe from './slices/surprise-me';
import existingProjectList from './slices/existing-project-list';
import subscription from "./slices/subscription";
import profile from "./slices/profile";
import uiConfig from "./slices/uiConfig";
import { apiSlice } from "./slices/api";
import { customMiddleware} from "./middleware"

export const store = configureStore({
  reducer: {
    counterReducer,
    snackbar,
    commonPopup,
    loader,
    topbar,
    solutions,
    surpriseMe,
    existingProjectList,
    subscription,
    profile,
    uiConfig,
    pricing,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  devTools: process.env.NEXT_PUBLIC_ENV !== "prod",
  middleware: (getDefaultMiddleware) =>
    process.env.NEXT_PUBLIC_ENV !== "prod"
      ? [
          ...getDefaultMiddleware(),
          logger,
          apiSlice.middleware,
          customMiddleware,
        ]
      : [...getDefaultMiddleware(), apiSlice.middleware, customMiddleware],
});
