import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAPI } from "@/http";
import axios from "axios";
import auth from "@/utils/auth";
import { RE1_BACKEND_URL } from "@/ui-constants";
import { customAPIV2 } from "@/apis/http";
const initialState = {
  isLoading: false,
  data:{},
  // data: {
  //     "bar": {
  //         "backgroundColor": "#21aa93",
  //         "backgroundTransparency": "0",
  //         "fontColor": "#F9F9F9",
  //         "highlightFontColor": "#DD5C64",
  //         "placement": "top",
  //         "borderRadius": "0",
  //         "addCloseIcon": false,
  //         "unStyled": true,
  //         "container": null,
  //         "fontSize": "1rem"
  //     },
  //     "couponCode": "pdqnsrpn50",
  //     "discountPercentage": "50.00",
  //     "data": {
  //         "stripePromoCodeId": "promo_1PJYruJoH3SERBih9enY8zjB"
  //     },
  //     "country": "India",
  //     "countryFlag": "🇮🇳",
  //     "holiday": null,
  //     "time": null,
  //     "countryCode": "IN",
  //     "currencyCode": "INR",
  //     "currencySymbol": "₹",
  //     "usdConversionRate": "83.124632",
  //     "isVpn": false,
  //     "isProxy": false,
  //     "isTor": false
  // },
};

export const fetchParityDetails = createAsyncThunk(
  "pricing/fetch_parity",
  async (data, thunkAPI) => {
    const response = await customAPI(
      "get",
      `https://api.paritydeals.com/api/v1/deals/discount/?url=${window.location.href}`,
      {},
      {
        dispatch: thunkAPI.dispatch,
        loaderMsg: "Loading",
        disableLoader: true,
        disableSnackbar: false,
      }
    );
    return response;
  }
);


const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchParityDetails.fulfilled, (state, action) => {
      state.isLoading= false
      state.data = action.payload;
    })
  },
});

export default pricingSlice.reducer;
