import { REVERSE_SOLUTION_SCHEMA_MAPPING } from "@/ui-constants";
import {
  setPreviousSolution,
  setProjectName,
  setSolutionSchema,
  setUploadImgAndSpaceType,
} from "./slices/solutions";
import { fetchSubscriptionDetails } from "./slices/subscription";
import { enablePurchasePopup } from "./slices/uiConfig";
import isSubscriber from "@/utils/isSubscriber";
export const customMiddleware = (store) => (next) => (action) => {
  if (
    action.type === "surprise-me/surpriseMeApiThunk/pending" ||
    action.type === "surprise-me/regenerateSurpriseMeApiThunk/pending"
  ) {
    if (store.getState().subscription.data.credits_left < 3) {
      store.dispatch(enablePurchasePopup());
      throw new Error("Aborted");
    }
    const result = next(action);
    store.dispatch(fetchSubscriptionDetails());
    return result;
  }
  if (
    action.type === "solutions/createGenerationThunk/pending" ||
    action.type === "solutions/createGenerationVS_EmptyThunk/pending"
  ) {
    if (!isSubscriber(store.getState().subscription.data)) {
      store.dispatch(enablePurchasePopup());
      throw new Error("Aborted");
    }
    const result = next(action);
    store.dispatch(fetchSubscriptionDetails());
    return result;
  }
  if (
    action.type === "surprise-me/surpriseMeApiThunk/fulfilled" ||
    action.type === "surprise-me/regenerateSurpriseMeApiThunk/fulfilled" ||
    action.type === "solutions/createGenerationThunk/fulfilled" ||
    action.type === "solutions/createGenerationVS_EmptyThunk/pending"
  ) {
    const result = next(action);
    store.dispatch(fetchSubscriptionDetails());
    return result;
  }

  if (action.type === "subscription/fetch_subscription/pending") {
    if (store.getState().subscription.isLoading) {
      throw new Error("Aborted");
    }
  }
  if (action.type === "solutions/setSolutionSchema") {
    if (
      store.getState().solutions.previousFurnishedStatus.solution_id !=
      REVERSE_SOLUTION_SCHEMA_MAPPING[action.payload.solutionName]
    ) {
      store.dispatch(
        setPreviousSolution({
          solution_id:
            REVERSE_SOLUTION_SCHEMA_MAPPING[action.payload.solutionName],
        })
      );
    }
    const result = next(action);
    return result;
  }

  // if (action.type === "solutions/createNewProjectThunk/pending") {
  //   store.dispatch(
  //     setSolutionSchema({
  //       solutionName: action.payload.solution,
  //     })
  //   );

  //   store.dispatch(
  //     setProjectName({ projectName: action.payload.project.name })
  //   );

  //   store.dispatch(
  //     setUploadImgAndSpaceType({
  //       url: action.payload.media.url,
  //       spaceType: action.payload.media.space_type,
  //     })
  //   );
  //   const result = next(action);
  //   return result;
  // }

  return next(action);
};
