import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAPI } from "@/http";

const initialState = {
  isLoading: false,
  data: {
  },
};

export const fetchSubscriptionDetails = createAsyncThunk(
  "subscription/fetch_subscription",
  async (data, thunkAPI) => {
    try {
      const response = await customAPI(
        "get",
        `/subscription`,
        {},
        {
          dispatch: thunkAPI.dispatch,
          loaderMsg: "Loading your data please wait",
          disableLoader: true,
          disableSnackbar: false,
        }
      );
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue({});
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state) => {
        state.isLoading = false;
      })

  }
});


export default subscriptionSlice.reducer;
